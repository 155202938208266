function lightboxAlertstockCustom() {
    $('.container_lightbox_alert_stock .stock_alert_description').hide();
}

// Function used on quantity selector evolution
function updateProductQuantity(action, prod_id) {
    var qte_stock,
        qte_input,
        plus_selector,
        minus_selector;
    var is_wishlist = $('#wishlist').length > 0;

    // Recover html selectors
    plus_selector = $("#ligne_qte .qte_plus");
    minus_selector = $("#ligne_qte .qte_minus");

    // Get product stock quantity and input quantity selected values
    qte_stock = $('#product_stock_counter').val();
    qte_stock = (qte_stock != '') ? qte_stock : 0;
    qte_input = $('#ligne_qte .qte_selected').val();
    qte_input = (qte_input != '') ? qte_input : 0;

    if (action == 'plus') {
        qte_input++;

        // Remove disabled classes if necessary
        if ((qte_input > 1) && (minus_selector.hasClass('selector_disabled'))) {
            minus_selector.removeClass('selector_disabled');
        }

        // Update form
        if (qte_input <= qte_stock) {
            $('#qteProd').val(qte_input);
        } else {
            qte_input--;
            plus_selector.addClass('selector_disabled')
        }

    } else if (action == 'minus') {
        qte_input--;

        // If qte stored in input can be decremented
        if ((qte_input > 1) || (qte_input == 1)) {
            $('#qteProd').val(qte_input);
        }

        // add diabled class if needed
        if (qte_input == 1) {
            if (!minus_selector.hasClass('selector_disabled')) {
                minus_selector.addClass('selector_disabled');
            }
        }
    }

    // Update input holding stock counter to add security : if qte = 0 you can't add it to your cart
    getAvailableQuantity(prod_id, qte_input, 'product', '', is_wishlist);
}

function seeAllDescription() {
    // Gestion d'ouverture de l'onglet description et fermeture des autres onglets
    if (!$("#description_longue").hasClass("actif")) {
        $(".product_detail").removeClass("actif");
        $(".product_detail_content").hide();
        $("#description_longue").addClass("actif");
        $("#description_longue .product_detail_content").slideDown();
    }

    // Récupération de l'emplacement de l'onglet description et gestion de l'offset en fonction du menu et du preheader
    var offset = ($("#description_longue")).offset();
    var offsetPreheader;
    var offsetMenu;

    if ($("#site_head_wrap").hasClass("bandeau_on")) {
        offsetPreheader = $("#header_mention_id").height();
    } else {
        offsetPreheader = 0;
    }

    offsetMenu = $("#top_wrapper").height();

    var offsetTop = offset.top - offsetMenu - offsetPreheader - 20;

    // Scroll vers l'onglet description
    setTimeout(function () {
        $("html, body").animate({
            scrollTop: offsetTop
        }, 200);
    }, 250);
}

// Called upon size change in product page
function onItemSizeChange(form, is_regroup, itm_rr_id, change_size = true) {

    var control;
    var isWishList = $('#wishlist').length > 0;
    var isAchatExpressV2 = ($('#is_achat_express_v2').val() == 1 && !isWishList);
    var productId = form.getValue('produit_id');
    var productRef = form.getValue('produit_ref');
    control = form.elements.namedItem('tailleProd');
    control.value = this.value.trim();
    var from = ($('#is_from_lookbook').val() !== undefined) ? 'lookbook' : 'product';
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if (isAchatExpressV2) {
        if (this.checked) {

            if ($('#sizeerror').is(':visible')) {
                $('#sizeerror').slideUp();
            }

            loadProduct.call(form, productId, 'taille', from, itm_rr_id, 'sizeChange');

            // Depending on the color we see if the product is available, do this only in product page
            // We check if the product is already in the wishlist
            checkProductInWishlist(productId, productRef, true);
        }
    } else {

        if ($('#sizeerror').is(':visible')) {
            $('#sizeerror').slideUp();
        }
        if (is_regroup) {
            loadProduct.call(form, productId, 'taille', from, itm_rr_id, 'sizeChange');
        }
    }

    if (change_size && window.dataLayer !== undefined) {
        window.dataLayer.push({
            'event': 'changeSize',
            'produit': {
                'size_value': ($(this).attr('data-nom')),
            }
        });
        if (window.dataLayer[0].product) {
            window.dataLayer[0].product.size = ($(this).attr('data-nom'));
        }
    }
}

// Pretty straightforward: this formats a price
var formatPrice = (function () {
    var THREEDIGITS = new RegExp("\\d{3}", "g");
    var FORMAT = window.price_format_plain;

    // Splits a number into chunks of 3 digits
    function splitThousands(nmbr) {
        var f_chunk = "",
            chunk_length;

        nmbr = String(nmbr);
        chunk_length = nmbr.length % 3;

        if (chunk_length) {
            f_chunk = nmbr.slice(0, chunk_length);
            nmbr = nmbr.substr(chunk_length);
        }

        return (
            f_chunk +
            nmbr.replace(THREEDIGITS, function (match) {
                return " " + match;
            })
        );
    }

    return function (price) {
        var CURRENCY = window.siteCurrency;
        var output;

        if (price.constructor !== String) {
            price = price.toString(10);
        }

        price = price.replace(".", ",");
        price = price.split(",");

        if (price[1] === undefined) {
            price[1] = '00';
        } else if (price[1].length == 1) {
            price[1] = price[1] + '0';
        }
        output = FORMAT.replace("[<unit>]", splitThousands(price[0]));
        output = output.replace("[<symbol_right>]", CURRENCY.symbol_right);
        output = output.replace("[<symbol_left>]", CURRENCY.symbol_left);
        output = output.replace("[<decimal>]", price[1]);
        output = output.replace("[<code>]", CURRENCY.code);

        return output;
    };
})();

/**
 * Filter orders by type
 */
function show_orders_by_type(elementID, type, parentPath, reload) {

    var str_year = '';
    var str_reload = '';
    var year = $("#year").val();

    if (year != '') {

        str_year = '&year=' + year;
    }

    if (reload !== undefined) {

        str_reload = '&reload=true';
    }

    // Gestion de la surbrillance des onglets
    var i = 1;
    while (document.getElementById("com_onglet_" + i)) {

        if (type == i) {

            $("#com_onglet_" + i).addClass("actif");
        } else {

            $("#com_onglet_" + i).removeClass("actif");
        }
        i++;
    }

    var container = document.getElementById(elementID);

    //clearTimeout(time_out);
    if (container) {

        var response = ajax_file(parentPath + 'ajax_account_orders_by_type.php?type=' + type + str_year + str_reload);
        if (response) {

            if (reload !== undefined) {

                $('.content_commandes').html(response);
            } else {

                $(container).html(response);
            }
        } else {

            container.innerHTML = '';
        }
    }
}


/**
 * Change cart product quantity
 */
function changeQte(type) {

    var types = ['plus', 'less'],
        item, loader, qtty, ajax_url;

    if (types.includes(type)) {

        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);

        $(this).find('.selector .qteWrapper_bot').hide();
        loader.style.display = 'block';

        switch (type) {

            case 'plus':
                qtty += 1;
                break;

            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url: path_relative_root + create_link('order_basket'),
            type: 'post',
            data: {
                action: 'del',
                panierId: item,
                cad: 'non',
                pointcad: 0,
                qteProd: qtty,
                change_qte: true
            },
            cache: false,
            success: function (res) {

                try {

                    res = JSON.parse(res);
                } catch (e) {

                    console.error('JSON ERROR: could not parse data');

                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();

                wShop.$refs.wCouponForm.reloadBloc += 1;
                //update top cart
                var response = ajax_file("ajax_show_basket.php");

                cart = Translator.translate('mon_panier');


                document.getElementById('cart_top').innerHTML = response;

                var total = $('#cart_top .itm_menu').attr('data-cart'),
                    text;

                if (total <= 1) {
                    text = Translator.translate('one_product');
                } else {
                    text = Translator.translate('2_product');
                }

                $('.cart_main_title.basket').html(cart + ' ' + '<span class="nb_products">(' + total + ' ' + text + ')</span>');

            },
            complete: function () {
                dragDropWishlist();
                updateCartElements();
            }
        });
    }
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {
    page = page || 1;
    var page_bloc = parseInt(page - 1);

    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    // Si on est sur la page de recherche
    if ($('#search_page').length > 0) {

        generateNewBlocSearch(page_bloc);
    } else {

        generateNewBlocProd(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop, true);
    }
}

function paginate(page) {

    if (document.getElementById('filter_sticky')) {
        var form = document.getElementById('filter_sticky'),
            total_element = form.getValue('totalElems', parseFloat),
            nb_prod = form.getValue('nb_prod', parseFloat),
            nb_total_page = Math.ceil(total_element / nb_prod),
            pagination = $('.pager_wrapper'),
            nb_prod_default = form.getValue('nb_prod_default', parseFloat);
        see_all = form.getValue('see_all', parseFloat);

        page = page || 1;

        $.ajax({
            url: path_relative_root + 'ajax_reload_pagination.php',
            type: 'get',
            data: {
                page: page,
                link: 'paginationGoToPage()',
                nb_total_page: nb_total_page
            },
            success: function (res) {

                var productLoaded = $('#list_item .item:not(".bloc_push")').length;
                var percentLoaded = (productLoaded * 100 / total_element);

                pagination.each(function () {

                    if (nb_prod != 1000 && nb_prod < total_element) {
                        this.innerHTML = res + '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product"></div>';
                    } else if (total_element > nb_prod_default) {
                        this.innerHTML = '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product"></div>';
                    } else {
                        this.innerHTML = '';
                    }
                });
            }
        });
    }
}

function updateCartElements() {
    /* init swiper panier */
    if ($('body.step_1 .wrapperCartAssociations').length) {
        var swiperAssociations = new Swiper('.wrapperCartAssociations #cartAssociations', {
            roundLengths: true,
            setWrapperSize: true,
            slidesPerView: "auto",
            spaceBetween: 30,
            navigation: {
                nextEl: '.assoc-button-next.slide_next',
                prevEl: '.assoc-button-prev.slide_prev',
            },
        });
    }

    /* Scrollbar Listes des Produits Panier */
    $(".top_basket_wrapper").overlayScrollbars({
        scrollbars: {
            autoHide: "leave",
            autoHideDelay: 0,
        }
    });
}

function getWishlistProducts() {
    let array_elements = document.querySelectorAll('a[data-productid]');

    let array_ids = [];
    if (typeof array_elements != 'undefined' && array_elements.length > 0) {
        for (el of array_elements) {
            var id = el.getAttribute('data-productid');

            if (id !== '' && id !== 'undefined') {
                array_ids.push(id);
            }
        }
        let data = JSON.parse((JSON.stringify(Object.assign({}, array_ids))));

        $.ajax({
            type: 'get',
            url: path_relative_root + 'get_user_all_wishlist.php',
            data: data,
            success: function (response) {
                if (response) {
                    if (typeof response != 'undefined' && response.length > 0) {
                        let array_product_id = JSON.parse(response);
                        for (product_wishlist in array_product_id) {
                            $('a[id = "addToWishlistButton_' + array_product_id[product_wishlist].fk_produit_id + '"]').addClass("full_heart");
                        }
                    }
                }
            }
        });
    }
}

$(function () {
    /* ________ HOME ________*/

    if ($('#coverSwiper').length) {
        new Swiper('#coverSwiper.swiper', {
            roundLengths: true,
            setWrapperSize: true,
            slidesPerView: 1,
            autoplay: true,
            loop: true,
            speed: 3000,

            pagination: {
                el: '#coverSwiper .swiper-pagination',
            },
        })
    }

    if ($('#swiperTg').length) {
        if ($('#block_news').length) {
            new Swiper('#swiperTg.swiper', {

                roundLengths: true,
                setWrapperSize: true,
                slidesPerView: 2.6,
                spaceBetween: 30,

                breakpoints: {
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    1550: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                },
                navigation: {
                    nextEl: '.swiperTg-button-next',
                    prevEl: '.swiperTg-button-prev',
                },
            })
        } else {
            new Swiper('#swiperTg.swiper', {

                roundLengths: true,
                setWrapperSize: true,
                slidesPerView: 2.6,
                spaceBetween: 30,
                grid: {
                    rows: 1,
                    fill: 'row',
                },

                breakpoints: {
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                        grid: {
                            rows: 2,
                        },
                    },
                    1550: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                        grid: {
                            rows: 2,
                        },
                    },
                },
                pagination: {
                    el: '#swiperTg .swiper-pagination',
                },
            })
        }
    }

    if ($('.home-module.image-image').length) {
        new Swiper('.home-module.image-image', {
            roundLengths: true,
            setWrapperSize: true,
            slidesPerView: 1.5,
            spaceBetween: 20,
            breakpoints: {
                1300: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
            }
        })
    }

    /**
     * Product net reviews
     */
    if ($('.swiperNetreviews').length) {

        var swiperNetreviews = new Swiper('.swiperNetreviews.swiper', {

            slidesPerView: 2.2,
            roundLengths: true,
            setWrapperSize: true,
            spaceBetween: 30,
            navigation: {
                nextEl: '.netreviews-button-next',
                prevEl: '.netreviews-button-prev',
            },
            scrollbar: {
                el: ".swiperNetreviews .swiper-scrollbar",
                draggable: true,
            },
        });
    }

    if ($('.home-module.blog-module').length) {
        new Swiper('.home-module.blog-module .swiper', {
            roundLengths: true,
            setWrapperSize: true,
            cssWidthAndHeight: true,
            slidesPerView: 1.6,
            visibilityFullFit: true,
            autoResize: false,
            spaceBetween: 30,
            breakpoints: {
                1690: {
                    slidesPerView: 3,
                },
                1440: {
                    slidesPerView: 2.6,
                },
                1300: {
                    slidesPerView: 2.4,
                },
                1160: {
                    slidesPerView: 2.2,
                },
                1030: {
                    slidesPerView: 2,
                },
            }
        })
    }


    /** PAGES ADV **/
    /* tg swiper */
    if ($('.tgCms').length) {
        new Swiper('.tgCms.swiper', {

            roundLengths: true,
            setWrapperSize: true,
            slidesPerView: 2.6,
            spaceBetween: 30,

            breakpoints: {
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1550: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
            },
            navigation: {
                nextEl: '.tg-module .cmstg-button-next',
                prevEl: '.tg-module .cmstg-button-prev',
            },
        })
    }

    /*Sets Module TITRE + IMAGE above breadcrumbs*/
    if ($(".cms_page .cms-page-module.image-text-1").length) {
        $("#cms_page_ariane").insertAfter($(".cms-page-module.image-text-1"));
        $(".cms_page #cms_page_ariane").css("display", "flex");
    } else {
        $(".cms_page #cms_page_ariane").css("display", "flex");
    }

    //publication swiper
    if ($('.cms-page-module.blog-module').length) {
        new Swiper('.cms-page-module.blog-module .swiper', {
            roundLengths: true,
            setWrapperSize: true,
            cssWidthAndHeight: true,
            slidesPerView: 1.6,
            visibilityFullFit: true,
            autoResize: false,
            spaceBetween: 36,
            navigation: {
                nextEl: '.cms-blog-button-next',
                prevEl: '.cms-blog-button-prev',
            },
            breakpoints: {
                1690: {
                    slidesPerView: 3,
                },
                1440: {
                    slidesPerView: 2.6,
                },
                1300: {
                    slidesPerView: 2.4,
                },
                1160: {
                    slidesPerView: 2.2,
                },
                1030: {
                    slidesPerView: 2,
                },
            }
        })
    }

    /****** PAGES MARQUES SWIPERS ******/

    if ($('.product_actif.swiper').length) {
        var swiperTopBrand = new Swiper('.product_actif.swiper', {
            freeMode: true,
            slidesPerView: "auto",
        })
    }

    if ($('.marques_nav.swiper').length) {
        var swiperMarquesNav = new Swiper('.marques_nav.swiper', {
            freeMode: true,
            slidesPerView: "auto",
            spaceBetween: 6,
        })
    }

    if ($('.marque_gondole.swiper').length) {
        var swiperMarque = new Swiper(".marque_gondole.swiper", {
            preloadImages: true,
            lazyLoading: true,
            roundLengths: true,
            setWrapperSize: true,
            slidesPerView: 2.6,
            spaceBetween: 30,

            breakpoints: {
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1550: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
            },
            navigation: {
                nextEl: '.assocBrand-button-next',
                prevEl: '.assocBrand-button-prev',
            },
        });
    }

    /*** FICHE PRODUIT ***/
    /** Ouverture Fermeture Onglet Informations */
    $(".product_detail_wrapper .product_detail_title").on("click", function () {
        // Si on clique sur l'onglet ouvert => Fermeture de l'onglet
        if ($(this).parent().hasClass("actif")) {
            $(this).parent().removeClass("actif");
            $(this).siblings(".product_detail_content").removeClass("actif");

            // Si on clique sur un autre onglet => Fermeture des onglets ouverts et ouverture de l'onglet sélectionné
        } else {
            $(".product_detail").removeClass("actif");
            $(".product_detail_content").hide();
            $(this).parent().addClass("actif");
            $(this).siblings(".product_detail_content").slideDown();

            // scroll to the title which has been clicked
            var scrollOffset = $(this).offset().top - 200; 
            $('html, body').animate({
                scrollTop: scrollOffset
            }, 500); 
        }
    })


    setTimeout(() => {
        if ($('#gondole_aimerez').length) {
            var swiperAssociations = new Swiper('#gondole_aimerez', {
                slidesPerView: "auto",
                spaceBetween: 30,
                navigation: {
                    nextEl: '.associations-button-next',
                    prevEl: '.associations-button-prev',
                },
            });
        }
    }, 500);

    if ($('.associated-product-lightbox').length) {
        var swiperAssociations = new Swiper('.associated-product-lightbox', {
            slidesPerView: "auto",
            spaceBetween: 21,
            pagination: {
                el: '.associated-product-lightbox-pagination',
                type: 'bullets',
            },
            navigation: {
                nextEl: '.associations-lightbox-button-next',
                prevEl: '.associations-lightbox-button-prev',
            },
        });
    }

    if ($('.product_page #ligne_couleur').length) {
        $('#ligne_pointure').insertBefore($('.description_courte'));
        $('#ligne_pointure').addClass('visible');
    }

    /****** CART ASSOCIATION SWIPER ******/

    if ($('body.step_1 .wrapperCartAssociations').length) {
        var swiperAssociations = new Swiper('.wrapperCartAssociations #cartAssociations', {
            roundLengths: true,
            setWrapperSize: true,
            slidesPerView: "auto",
            spaceBetween: 30,
            navigation: {
                nextEl: '.assoc-button-next.slide_next',
                prevEl: '.assoc-button-prev.slide_prev',
            },
        });
    }

    getWishlistProducts();

    function handleBankSelection() {
        $(".wrapper_bloc_banque").on("click", function () {
            
            // On grise les autres
            $(".wrapper_bloc_banque").not($(this)).removeClass("actif");

            if (!$('.gift_card').hasClass('actif')) {
                // Si 'gift_card' était actif mais ne l'est plus, on déclenche le clic
                $('#gifCardInvisibleInput').click();
            }
    
            // On active la courrante
            $(this).addClass("actif");
    
            if (!$(this).hasClass("facilipay") && !$(this).hasClass("cofidis3x") && $("#recapAdresse").is(":visible")) {
                $("#recapAdresse").slideUp();
            } else if (($(this).hasClass("facilipay") || $(this).hasClass("cofidis3x")) && !$("#recapAdresse").is(":visible")) {
                $("#recapAdresse").slideDown();
            }
    
            if (!$(this).hasClass("credit_card") && !$(this).hasClass("gift_card") && !$(this).hasClass("facilipay") && !$(this).hasClass("cofidis3x") && !$(this).hasClass("alias_list_wrapper") && !$(this).hasClass("bloc_virement") && !$(this).hasClass("bloc_paid_by_link_hipay") && !$(this).hasClass("payment_in_store")) {
                $("form", this).submit();
            }
        });
    }
    
    handleBankSelection();

    $(document).on('click', '.bloc_add_alert_confirmation .close_bloc_add_alert_confirmation', function() {
        var wrapper_product = $(this).parents('.wrapperProdVertical');
        var wrapper_product_itm;

        if (wrapper_product.attr('data-prod') && wrapper_product.attr('data-prod').trim() !== '') {
            wrapper_product_itm = wrapper_product.attr('data-prod');
        } else if (wrapper_product.attr('data-produit-id') && wrapper_product.attr('data-produit-id').trim() !== '') {
            wrapper_product_itm = wrapper_product.attr('data-produit-id');
        } else {
            wrapper_product_itm = wrapper_product.attr('data-id');
        }

        $(this).parent().slideUp();
        wrapper_product.find('.container_achat_express .bloc_add_color:not(:has(button[onclick^="achatExpressV2Batch"])').slideDown().removeClass('loading');
        wrapper_product.find('.container_achat_express #bloc_alert_stock_' + wrapper_product_itm).css('display', 'flex');
        wrapper_product.find('.bloc_add_alert_form, .alert_stock').slideDown();
        wrapper_product.find('#bloc_add_alert_' + wrapper_product_itm).slideUp();

        if (wrapper_product.find('.formBatch').hasClass('open')) {
            wrapper_product.find('.formBatch').removeClass('open').slideUp();
        }
    });

    $('.satellite_politique_de_confidentialite .content_sat .cookieShowLightbox').click(function() {
        $('.satellite_politique_de_confidentialite #cookiesLightbox').addClass('displayCookies');
        if($('.satellite_politique_de_confidentialite #cookiesLightbox').has('.hideCookies')) {
            $('.satellite_politique_de_confidentialite #cookiesLightbox').removeClass('hideCookies');
        }
        $('.satellite_politique_de_confidentialite #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.satellite_politique_de_confidentialite #js_cookie_refuse, .satellite_politique_de_confidentialite #js_cookie_accept').click(function() {
        if($('.satellite_politique_de_confidentialite #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.satellite_politique_de_confidentialite #cookiesLightbox').removeClass('displayCookies');
                $('.satellite_politique_de_confidentialite #cookiesLightbox').addClass('hideCookies');
                $('.satellite_politique_de_confidentialite #shadCookie').removeClass('actif');
            }, 500);
        }
    });
});

$(document).ready(function() {
    var oneSizeNone = $('.shoesize:contains("TAILLE UNIQUE"), .sub_taille:contains("TAILLE UNIQUE")');

    if (oneSizeNone.length) {
        oneSizeNone.hide();
    }
});